import React, { Component } from 'react';
import PropTypes from 'prop-types';

import waves from 'assets/waves.gif';
import initial from 'assets/initial.png';

import { artwork } from './artwork.js';

export class Artwork extends Component {
    constructor() {
        super();
        this.state = {
            animateName: false,
            loading: true,
            loadedImages: [],
        };
    }

    handleImageLoaded(i, load) {
        const { loadedImages } = this.state;
        loadedImages.push(0);
        this.setState({ loadedImages });
        if (loadedImages.length > 7) {
            setTimeout(() => this.setState({ loading: false }), 1200);
        }
    }

    renderArt() {
        return (
            <div className={`artwork__work ${this.state.loading ? 'artwork__hide': 'artwork__show'}`}>
                <div className="row">
                    {artwork.map((art, i) => {
                        return (
                            <div className="artwork__piece-wrapper col-md-3 col-sm-4 col-12" key={art.image}>
                                <div className="artwork__piece">
                                    <span className="img" style={{ backgroundImage: `url(${art.image})`}} alt="" onLoad={this.handleImageLoaded.bind(this, i)}>
                                        <img style={{ display: 'none' }} src={art.image} alt="" onLoad={this.handleImageLoaded.bind(this, i)} />
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    renderLoading() {
        return (
            <div className="loading__wrapper">
                <img className="loading animate animate--fade-in-up" src={waves} alt="" />
            </div>
        )
    }
    
    render() {
        return (
            <div className="artwork animate--fade-in-up" >
                {this.renderArt()}
                {this.state.loading && this.renderLoading()}
                <img className="artwork__initial" src={initial} alt="" />
            </div>
        );
    }
}

Artwork.propTypes = {
    onDidCompleteSetup: PropTypes.func,
};
