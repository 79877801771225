import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 

import { projects } from './projects.js';

export class Projects extends Component {
    constructor() {
        super();
        this.state = {
            view: 'enter',
            animateProjects: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ animateProjects: true });
        }, 300);
    }
    
    render() {
        const { mode } = this.props;
        const heart = projects.length % 2 === 1;

        return (
            <div className="row">
                {projects.map((item, i) => {
                    return (
                        <div className="col-md-6 col-12" key={`project__item-${i}`}>
                            <div className={`projects__item ${mode}`}>
                                <Link to={`/projects/${item.id}`}>
                                    <div className={`projects__item-header item--${item.placement ? item.placement : 'BL'}`}>
                                        <div className="projects__title">{item.title}</div>
                                        <div className="projects__roles">
                                            {item.roles && item.roles.map((role, i) => (
                                                <div className="role" key={`project__role-${i}`}>
                                                    <div className="projects__role">{role}</div>
                                                    {i + 1 < item.roles.length && <div className="projects__dot"></div>}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <span className="img">
                                        <img src={item.images[0]} alt={item.title} />
                                    </span>
                                </Link>
                            </div>
                        </div>
                    );
                })}
                {heart && (
                    <div className="col-md-6 col-12 projects__heart">
                        <svg width="95" height="64" viewBox="0 0 95 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M32 57.5813C16.9867 42.8107 2.66667 30.1227 2.66667 19.176C2.66667 9.06667 10.848 5.33334 16.7493 5.33334C20.248 5.33334 27.8187 6.66934 32 17.2187C36.24 6.63734 43.904 5.36 47.2693 5.36C54.0427 5.36 61.3333 9.68267 61.3333 19.176C61.3333 30.0267 47.6373 42.176 32 57.5813ZM47.2693 2.69334C41.3947 2.69334 35.4133 5.472 32 11.328C28.5733 5.44534 22.608 2.66667 16.7493 2.66667C8.26133 2.66667 0 8.49867 0 19.176C0 31.6053 14.856 44.32 32 61.3333C49.1467 44.32 64 31.6053 64 19.176C64 8.48 55.7467 2.69334 47.2693 2.69334" fill="#F99E9D"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M63 57.5813C47.9867 42.8107 33.6667 30.1227 33.6667 19.176C33.6667 9.06667 41.848 5.33334 47.7493 5.33334C51.248 5.33334 58.8187 6.66934 63 17.2187C67.24 6.63734 74.904 5.36 78.2693 5.36C85.0427 5.36 92.3333 9.68267 92.3333 19.176C92.3333 30.0267 78.6373 42.176 63 57.5813ZM78.2693 2.69334C72.3947 2.69334 66.4133 5.472 63 11.328C59.5733 5.44534 53.608 2.66667 47.7493 2.66667C39.2613 2.66667 31 8.49867 31 19.176C31 31.6053 45.856 44.32 63 61.3333C80.1467 44.32 95 31.6053 95 19.176C95 8.48 86.7467 2.69334 78.2693 2.69334" fill="#F99E9D"/>
                        </svg>
                    </div>
                )}
            </div>
        );
    }
}
