import { Projects } from '../Projects';
import { Project } from '../Project';
import { Artwork } from '../Artwork';

export const routes = {
    projects: {
        path: ['/projects'],
        exact: false,
        component: Projects,
    },
    project: {
        path: ['/projects/:projectId'],
        exact: false,
        component: Project,
    },
    artwork: {
        path: ['/artwork'],
        exact: false,
        component: Artwork,
    },
    about: {
        path: ['/about'],
        exact: false,
        component: Artwork,
    },
};
