import Art_1 from 'assets/artwork/1.png';
import Art_2 from 'assets/artwork/2.png';
import Art_3 from 'assets/artwork/3.png';
import Art_4 from 'assets/artwork/4.png';
import Art_5 from 'assets/artwork/5.png';
import Art_6 from 'assets/artwork/6.png';
import Art_7 from 'assets/artwork/7.png';
import Art_8 from 'assets/artwork/8.png';
import Art_9 from 'assets/artwork/9.png';
import Art_10 from 'assets/artwork/10.png';
import Art_11 from 'assets/artwork/11.png';
import Art_12 from 'assets/artwork/12.png';
import Art_13 from 'assets/artwork/13.png';
import Figures from 'assets/artwork/Figures.png';
import Fruit from 'assets/artwork/Fruit.png';
import Orange from 'assets/artwork/Orange.png';
import Strawberry_Blurred from 'assets/artwork/Strawberry_Blurred.png';
import Untitled_Artwork_3 from 'assets/artwork/Untitled_Artwork_3.png';
// import Untitled_Artwork_5 from 'assets/artwork/Untitled_Artwork_5.png';
import Untitled_Artwork_6 from 'assets/artwork/Untitled_Artwork_6.png';
import Hands from 'assets/artwork/Hands.png';
// import Harry_Wallpaper from 'assets/artwork/Harry_Wallpaper.png';
import Untitled_Artwork_7 from 'assets/artwork/Untitled_Artwork_7.png';
import Untitled_Artwork from 'assets/artwork/Untitled_Artwork.png';


export const artwork = [
    {
        image: Hands,
    },
    {
        image: Untitled_Artwork_7,
    },
    // {
    //     image: Untitled_Artwork_5,
    // },
    // {
    //     image: Harry_Wallpaper,
    // },
    {
        image: Untitled_Artwork_6,
    },
    {
        image: Figures,
    },
    {
        image: Orange,
    },
    {
        image: Fruit,
    },
    {
        image: Strawberry_Blurred,
    },
    {
        image: Untitled_Artwork_3,
    },
    {
        image: Untitled_Artwork,
    },
    {
        image: Art_1,
    },
    {
        image: Art_2,
    },
    {
        image: Art_3,
    },
    {
        image: Art_4,
    },
    {
        image: Art_5,
    },
    {
        image: Art_6,
    },
    {
        image: Art_7,
    },
    // {
    //     image: Art_8,
    // },
    {
        image: Art_9,
    },
    // {
    //     image: Art_10,
    // },
    // {
    //     image: Art_11,
    // },
    {
        image: Art_12,
    },
    {
        image: Art_13,
    },
];