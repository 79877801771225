import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import 'styles/main.scss';

import Hello from 'containers/Hello';
import { routes } from './routes';

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            helloLoaded: false,
            mode: 'light',
            animate: {
                logoOut: false,
            },
            colors: {
                light: {
                    background: '#efecec',
                    backgroundAlt: '#fbfafa',
                    font: '#241f1f',
                },
                dark: {
                    background: '#241f1f',
                    backgroundAlt: '#322b2b',
                    font: '#efecec',
                },
            }
        };
    }
    
    componentDidMount() {
        this.setLoading(false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.mode !== this.state.mode) {
            const body = document.getElementsByTagName('body');
            body[0].style.backgroundColor = this.state.colors[this.state.mode].background;
            body[0].style.color = this.state.colors[this.state.mode].font;
            // const projects = document.getElementById('projects');
            // projects.style.backgroundColor = this.state.colors[this.state.mode].backgroundAlt;
        }
    }

    setLoading = (loading) => {
        setTimeout(() => this.setState({ animate: { logoOut: true } }), 1700);
        setTimeout(() => this.setState({ loading }), 2000);
    };

    handleMode = () => {
        this.setState(prevState => ({ mode: prevState.mode === 'light' ? 'dark' : 'light' }));
    };

    render() {
        const { loading, animate, helloLoaded, mode } = this.state;

        if (loading) {
            return (
                <div className="loading__wrapper">
                    <h1 className={`loading animate animate--fade-in-up${animate.logoOut ? ' animate--fade-out-down' : ''}`}>hello</h1>
                </div>
            );
        }

        return (
            <React.Fragment>
                <Hello mounted={() => this.setState({ helloLoaded: true })} toggleMode={() => this.handleMode()} mode={mode} />
                <div className="content">
                    <div className={`projects projects--home ${mode}`} id="projects">
                        <div className="projects__wrapper">
                            <div className="projects__content container">
                                <Switch>
                                    {helloLoaded && Object.entries(routes).map(([key, route]) => (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            exact={route.exact}
                                            render={routeProps => <route.component {...routeProps} mode={mode} />}
                                        />
                                    ))}
                                    <Redirect from="/*" to="/projects" />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default App;
