import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import FontFaceObserver from 'fontfaceobserver';

import history from 'utils/history';

import App from 'containers/App/App';
import * as serviceWorker from 'serviceWorker';

// Observe loading of Open Sans (to remove open sans, remove the <link> tag in
// the index.html file and this observer)
const nowAltObserver = new FontFaceObserver('NowAltRegular', {});
const GTWalsheimProRegularObserver = new FontFaceObserver('GTWalsheimProRegular', {});
const GTWalsheimProBoldObserver = new FontFaceObserver('GTWalsheimProBold', {});

// When Open Sans is loaded, add a font-family using Open Sans to the body
Promise.all([nowAltObserver.load(), GTWalsheimProRegularObserver.load(), GTWalsheimProBoldObserver.load()]).then(
    () => {
        document.body.classList.add('fontLoaded');
    },
    e => {
        console.log('Font is not available:', e);
    },
);

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>, document.getElementById('root'));

serviceWorker.unregister();
