/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from 'react';
import { withRouter } from 'react-router'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Hello extends Component {
    static propTypes = {
        location: PropTypes.object,
        mounted: PropTypes.func,
        toggleMode: PropTypes.func,
        mode: PropTypes.string
    };

    constructor() {
        super();
        this.state = {
            animateName: '',
            animateRole: false,
            animateMenu: false,
            menuItems: ['projects', 'artwork', 'about'],
            selectedItem: 'projects'
        };
    }

    componentDidMount() {
        setTimeout(() => {
            window.innerWidth > 560 ? this.setState({ animateName: 'animate--move-top' }) : this.setState({ animateName: 'animate--fade-in-up' });
            setTimeout(() => {
                this.setState({ animateRole: true });
                setTimeout(() => {
                    this.setState({ animateMenu: true });
                    this.props.mounted();
                }, 400);
            }, 800);
        }, 300);        
    }

    handleChange = () => {
        this.props.toggleMode();
    };
    
    render() {
        const { menuItems, selectedItem } = this.state;
        const { mode } = this.props;

        return (
            <div className="hello" >
                <div className="hello__header">
                    <div className="hello__title">
                        <div className="hello__toggle">
                            <label className="switch" onChange={() => this.handleChange()}>
                                <input type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="hello__name">Alice<span>.</span></div>
                    </div>
                    <div className="hello__menu">
                        {menuItems.map((item, i) => (
                            <div key={`hello__menu-${i}`} className={`hello__menu-item ${selectedItem === item ? 'selected' : ''} ${mode}`}>
                                <Link to={`/${item}`} onClick={() => this.setState({ selectedItem: item })}>
                                    <span>{item}</span>
                                    <div className="selected"></div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={`hello__links ${mode}`}>
                    <a href="http://github.com/AliceMarshall" target="_blank"><i className="fa fa-github" aria-hidden="true"></i></a>
                    <a href="http://linkedin.com/in/alicemarshall-1" target="_blank"><i className="fa fa-linkedin-square" aria-hidden="true"></i></a>
                    <a href="mailto:marshall.alice92@gmail.com"><i className="fa fa-inbox" aria-hidden="true"></i></a>
                </div>
            </div>
        );
    }
}

export default withRouter(Hello);
