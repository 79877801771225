import React, { Component } from 'react';

export class Project extends Component {
    constructor() {
        super();
        this.state = {
        };
    }
    
    render() {
        return (
            <div className="project" >
                HELLO
            </div>
        );
    }
}
