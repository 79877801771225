import PM1 from 'assets/projects/pixel-motion-1.png';
import ART1 from 'assets/projects/artopia-1.png';
import KW1 from 'assets/projects/kano-world-1.png';
import D1 from 'assets/projects/disney-1.png';
import HP1 from 'assets/projects/harry-potter-1.png';
import CC1 from 'assets/projects/community-chest-1.png';
import BSU1 from 'assets/projects/big-stitch-up-1.png';
import SD1 from 'assets/projects/sink-the-dictatorships-1.png';

export const projects = [
    {
        title: 'Pixel Motion',
        id: 'pixel-motion',
        company: 'Kano Computing',
        roles: ['Software Engineer', 'Product Owner'],
        placement: 'TL',
        images: [PM1],
        description: 'After a Hack Day at my current company Kano Computing, myself and another developer desgined and created at multi-user drawing application. It\'s aimed at kids, as a fun and collaborative, gamified educational tool.',
        stack: 'Reactjs, Nodejs, GraphQL, AWS Amplify, AWS AppSync',
        link: 'beta.artopia.app',
        live: true,
    },
    {
        title: 'Artopia',
        id: 'artopia',
        company: 'Personal Project / Kano Computing',
        roles: ['Software Engineer', 'Co-founder', 'Artist'],
        placement: 'BL',
        images: [ART1],
        description: 'After a Hack Day at my current company Kano Computing, myself and another developer desgined and created at multi-user drawing application. It\'s aimed at kids, as a fun and collaborative, gamified educational tool.',
        stack: 'Reactjs, Nodejs, GraphQL, AWS Amplify, AWS AppSync',
        link: 'beta.artopia.app',
        live: true,
    },
    {
        title: 'Kano World',
        id: 'kano-world',
        company: 'Kano Computing',
        roles: ['Software Engineer', 'Backend Engineer'],
        placement: 'TL',
        images: [KW1],
        description: '',
        stack: 'Polymer3, LitElement, Vanilla JavaScript, Google Blockly',
        link: '',
        live: true,
    },
    {
        title: 'Disney Coding Apps',
        id: 'disney-coding-apps',
        company: 'Kano Computing',
        roles: ['Software Engineer', 'Scrum Master'],
        images: [D1],
        description: '',
        stack: 'Polymer3, LitElement, Vanilla JavaScript, Google Blockly',
        link: '',
        live: true,
    },
    {
        title: 'Harry Potter Coding App',
        id: 'harry-potter-coding-apps',
        company: 'Kano Computing',
        roles: ['Software Engineer', 'Scrum Master'],
        placement: 'BR',
        images: [HP1],
        description: '',
        stack: 'Polymer3, LitElement, Vanilla JavaScript, Google Blockly',
        link: '',
        live: true,
    },
    {
        title: 'Kano E-commerce Site',
        id: 'kano-ecommerce',
        company: 'Kano Computing',
        roles: ['Software Engineer', 'Scrum Master'],
        images: [ART1],
        description: '',
        stack: 'Polymer3, LitElement, Vanilla JavaScript, Google Blockly',
        link: '',
        live: true,
    },
    {
        title: 'Community Chest',
        id: 'community-chest',
        company: 'General Assembly: Final Project',
        roles: ['Software Engineer', 'UX Designer'],
        images: [CC1],
        description: 'I wanted to create a platform where friends can borrow and lend items, which are used infrequently. But to have an element of trust with who you are lending to, users can set a lending level on each item, either lendable to just friends or friends of friends. I found and implemented a gem called ‘has-friendship’ to create the friendship relationship between users.',
        stack: 'Ruby on Rails, PostgreSQL, Nodejs, Express, Angular2, SASS',
        link: 'https://stormy-plains-97072.herokuapp.com/home',
        live: true,
    },
    {
        title: 'Big Stitch Up',
        id: 'big-stitch-up',
        company: 'General Assembly: Project 2',
        roles: ['Software Engineer', 'UX Designer'],
        images: [BSU1],
        description: '',
        stack: 'Express, MongoDB, Nodejs, jQuery',
        link: 'https://big-stitch-up.herokuapp.com/',
        live: true,
    },
    {
        title: 'Sink the DictatorShips',
        id: 'sink-the-dictatorships',
        company: 'General Assembly: Project 1',
        roles: ['Software Engineer', 'UX Designer', 'Game'],
        placement: 'TR',
        images: [SD1],
        description: '',
        stack: 'HTML5, CSS, jQuery',
        link: 'https://secure-beyond-16634.herokuapp.com/',
        live: true,
    },
];